@charset 'utf-8';

// Fonts ///////////////////////////////////////////////////////////////
// For Bootstrap
$font-family-sans-serif: 'poynter-gothic-text', 'ryo-gothic-plusn', '游ゴシック', YuGothic, 'メイリオ', Meiryo, 'ヒラギノ角ゴPro W3', 'Hiragino Kaku Gothic Pro', sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";


// Colors //////////////////////////////////////////////////////////////
// For Bootstrap
$min-contrast-ratio: 2.5;

$red: #B00004;
$gray: #555555;
$blue: #154489;
$primary: $red;
$secondary: $gray;
$green:  #89A346;

// For Mobiscroll 5
$mbsc-ios-accent: $blue;
$mbsc-mobiscroll-accent: $blue;

// Custom


// Spacing /////////////////////////////////////////////////////////////
$enable-negative-margins: true;
$navbar-nav-link-padding-x: 1rem;


// Sizes ///////////////////////////////////////////////////////////////
$header-logo-size: 2rem;
