@import './custom/asj_casabella_constants';                        // Bootstrap より先に既定値を設定
@import "~bootstrap/scss/bootstrap";                               // Bootstrap 実体
@import '@mobiscroll/jquery/dist/css/mobiscroll.scss';             // カレンダーウィジェット用 (主に建築家相談会サイトで使用)
@import 'select2/src/scss/core';                                   // Select2 用

// Fix for https://github.com/apalfrey/select2-bootstrap-5-theme/issues/75 until Bootstrap 5.3 supported.
$s2bs5-border-color: $border-color;
@import 'select2-bootstrap-5-theme/src/select2-bootstrap-5-theme'; // Select2 の Bootstrap 5 テーマ

@import 'hover.css/css/hover-min.css';                             // ホバーエフェクト
@import './vendor/galliard';                                       // ASJ ローマ字フォント
@import './custom/asj_bootstrap_includes';                         // Boostrap 向け mixin など
@import './custom/asj_font';
@import './custom/asj_casabella_jekyll';                           // Jekyll で追加した定義
@import './sessions';

body.asj-bs {
  &.layout-casabella {
    @mixin no-underline {
      text-decoration: none;
    }

    @mixin dl-single-row {
      display: flex;
      margin-bottom: 0;
      dt, dd {
        margin-bottom: 0;
      }

      dt {
        white-space: nowrap;

        &.colon::after {
          content: '：';
        }
        &.ascii-colon::after {
          content: ':';
          margin-right: 0.25em;
        }
        &.equals::after {
          content: '＝';
        }
      }
    }

    #results,
    [id^="work-"],
    [id^="figure-"],
    [id^="footnote"],
    [id^="ref-"] {
      position: relative;
      top: -10vh;
      visibility: hidden;
    }

    #results,
    [id^="work-"],
    [id^="figure-"] {
      display: block;
    }

    cite {
      font-style: normal;
    }

    figure {
      &.quote {
        blockquote {
          margin-bottom: 0;
          p {
            margin-bottom: 0;
          }
        }
      }
    }

    footer {
      font-size: 0.8em;
      .copyright {
        flex-wrap: wrap;

        .year {
          flex-basis: 100%;
        }

        @include media-breakpoint-up(md) {
          flex-wrap: nowrap;
          .year {
            flex-basis: auto;
          }
        }
      }
    }

    form  {
      .select2-selection__rendered {
        display: inline-flex;
        margin-bottom: -0.375rem;
      }
      .select2-search--inline {
        display: inline;
      }
    }

    img {
      pointer-events: none;
    }

    sup {
      a {
        text-decoration: none;
        white-space: nowrap;
      }
    }

    dl.single-row {
      @include dl-single-row;
    }

    .fs-smaller {
      font-size: smaller;
    }

    .select2-results__options {
      li.select2-results__option {
        &:not([id]) {
          color: #6c757d !important; // Force non-choices to render in different color.
        }
      }
    }

    .small-tooltip .tooltip-inner {
      font-size: 0.86em;
    }

    .global-nav {
      .navbar-nav {
        .nav-item {
          a {
            line-height: 1.8em;
          }
        }
      }
    }

    .sub-header {
      h1 {
        font-size: 1.25rem;

        div {
          flex-basis: 100%;
          text-align: center;

          &.issue-released-at,
          &.issue-article-count {
            flex-basis: 50%;
          }

          @include media-breakpoint-up(md) {
            flex-basis: auto;
            &.issue-released-at,
            &.issue-article-count {
              flex-basis: auto;
            }

            .issue-name {
              text-align: left;
            }
            .issue-article-count {
              text-align: right;
            }
          }
        }

        @include media-breakpoint-down(sm) {
          font-size: 1rem;
        }
      }
    }

    .entries-and-pagination {
      .entries {
        text-align: left;
      }
      .pagination {
        margin-bottom: 0;

        .page-item {
          display: none;
          @include media-breakpoint-up(sm) {
            display: list-item;
          }
        }
        .first-page,
        .last-page,
        .page-gap,
        .prev-page,
        .next-page,
        .current-page {
          display: list-item;
        }
      }
      @include media-breakpoint-down(sm) {
        font-size: 0.86em;
        .entries {
          text-align: center;
          flex-basis: 100%;
          margin-bottom: 0.25em;
        }
        .pagination {
          .page-link {
            font-size: 0.86em;
          }
        }
      }
    }

    .card {
      &.issue,
      &.article {
        h2 {
          &.card-title,
          &.card-subtitle {
            font-size: 1.25em;
            @include media-breakpoint-up(sm) {
              font-size: 1em;
            }
          }
        }
        h3 {
          font-size: 1em;
        }

        .hero img, img.placeholder {
          height: 256px;
          max-height: 256px;
          object-fit: cover;
        }

        .building-kinds,
        .building-location,
        .designers,
        .authors {
          font-size: 0.84em;
        }

        .designers,
        .authors {
          dt {
            flex-basis: 3em;
          }
          dd {
            flex-grow: 1;
          }
        }

        .issue-and-page-links {
          .page-link {
            width: 100%;

            @include media-breakpoint-up(md) {
              width: auto;
            }
          }
        }
      }

      .card-link {
        &.disabled {
          pointer-events: none;
          opacity: 0.4;
        }
      }
    }

    .building-kinds {
      a {
        @include no-underline;
      }
    }
    .no-underline {
      @include no-underline;
    }

    .article-body {
      picture {
        source, img {
          max-height: 25vh;
          min-height: 160px;
          @include media-breakpoint-up(lg) {
            max-height: 30vh;
          }
        }
        img {
          @extend .border;
        }
      }

      p {
        line-height: 2rem;
        text-align: justify;

        text-indent: 1rem;
        &:first-of-type {
          text-indent: 0;
        }
      }

      dl {
        @include dl-single-row;
        dd {
          text-align: justify;
        }
      }

      a {
        &[data-fancybox] {
          text-decoration: none;
        }
      }
      p {
        &.no-indent {
          text-indent: 0;
        }
      }

      .no-indent {
        p {
          text-indent: 0;
        }
      }
      .force-indent {
        text-indent: 1rem !important;
      }

      .footnotes {
        li {
          text-align: justify;
        }
      }
    }

    // Article view
    &.casabella-articles-show {
      // For basic Fancybox UI
      .fancybox__cols {
        --right-col-width: clamp(150px, 20vw, 300px);

        grid-template-columns: minmax(0, 1fr) var(--right-col-width);
        display: grid;
        height: 100%;

        transition: all 0.35s cubic-bezier(0.23, 1, 0.32, 1);
      }

      .has-sidebar .fancybox__cols {
        grid-template-columns: minmax(0, 1fr) 0;
      }

      .fancybox__col {
        display: flex;
        flex-direction: column;
        min-height: 0;

        position: relative;
        overflow: hidden;
      }

      .fancybox__data {
        width: var(--right-col-width);
        padding: 1rem;
        height: 100%;
        overflow: auto;
      }
    }

    // "About" Page
    &.casabella-about {
      h2 {
        font-size: 1.25rem;
        font-weight: bold;
      }
      .links {
        figure {
          img {
            height: 44px;
          }
          margin-bottom: 0;
        }
      }
    }
  }

  // Box Content Preview PDF viewer layout
  &.layout-casabella_pdf {
    #pdf_preview {
      position:absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
}
