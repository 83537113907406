.roman-font {
  font-family: "ITC Galliard W01 Roman692801", "リュウミン L-KL", "Ryumin Light KL", "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", serif;
}
.futura {
  font-family: "futura-pt", sans-serif;
}

.mbsc-font {
  font-family: 'poynter-gothic-text', 'ryo-gothic-plusn', '游ゴシック', YuGothic, 'メイリオ', Meiryo, 'ヒラギノ角ゴPro W3', 'Hiragino Kaku Gothic Pro', sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
