@charset 'utf-8';

// Borders
@mixin bs-rounded-border {
  border: 1px solid $gray-300;
  border-radius: $border-radius;
}

.modal {
  .modal-body {
    position: relative;
    min-height: 4rem;

    .loading {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
    }
    .content {
      &.blurred {
        filter: opacity(0.4) blur(5px);
      }
    }
  }
}

.fs-smaller {
  font-size: smaller;
}

.w-1em {
  width: 1em !important;
}
.w-2em {
  width: 2em !important;
}
.w-3em {
  width: 3em !important;
}
.w-4em {
  width: 4em !important;
}
.w-5em {
  width: 5em !important;
}
.w-6em {
  width: 6em !important;
}
.w-7em {
  width: 7em !important;
}
.w-8em {
  width: 8em !important;
}
.w-9em {
  width: 9em !important;
}
.w-10em {
  width: 10em !important;
}
.w-11em {
  width: 11em !important;
}
.w-12em {
  width: 12em !important;
}
.w-13em {
  width: 13em !important;
}
.w-14em {
  width: 14em !important;
}
.w-15em {
  width: 15em !important;
}
.w-16em {
  width: 16em !important;
}

.d-flex-center {
  display: flex !important;
  align-items: center !important;
}
