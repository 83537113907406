@charset 'utf-8';

//////// decorations ////////

.cbj-dash {
  letter-spacing: -3px;
  white-space: nowrap;
  padding: 0 2px;
}

.text-dot {
  text-emphasis-style: dot;
}


//////// media object ////////

.media-object {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1rem;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;

  img {
    max-width: none;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .media-object.stack-for-small {
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
}

.media-object-section {
  -webkit-box-flex: 0;
  -webkit-flex: 0 1 auto;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;

  &:first-child {
    padding-right: 1rem;
  }
  &:last-child:not(:nth-child(2)) {
    padding-left: 1rem;
  }
  & > :last-child {
    margin-bottom: 0;
  }
}
@media print, screen and (max-width: 39.99875em) {
  .stack-for-small .media-object-section {
    padding: 0;
    padding-bottom: 1rem;
    -webkit-flex-basis: 100%;
    -ms-flex-preferred-size: 100%;
    flex-basis: 100%;
    max-width: 100%;

    img {
      /*width: 100%;*/
    }
  }
}
.media-object-section.main-section {
  -webkit-box-flex: 1;
  -webkit-flex: 1 1 0;
  -ms-flex: 1 1 0;
  flex: 1 1 0;
}



//////// list ////////

.li-asterisk, .li-kome {
  list-style: none;
  padding-left: 1.5em;

  li {
    text-indent: -1.5em;

    &:before {
      content: "※";
      margin-right: 0.5em;
    }
    a {
      text-indent: 0;
    }
  }
}

.li-asterisk {
  li:before {
    content: "＊";
  }
}

.li-kome {
  margin-left: 0.7em;
  li:before {
    content: "※";
  }
}

.li-dash {
  list-style-type: "—";
  padding-left: 1rem;
}

ol.colon-delimiter {
  list-style-position: inside;
  list-style-type: none;
  padding-left: 0;
  counter-reset: colon-list-counter;

  li {
    margin-bottom: 1em;
    counter-increment: colon-list-counter;

    &:before {
      content: counter(colon-list-counter) "：";
    }
  }
}


//////// gallery image height inherit ////////

.img-h-initial {
  max-height: initial!important;
}
